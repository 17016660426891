// src\pages\Login\profile.module.scss

@import '../../styles/mdr';

.profileWrapper {
  position: relative;
  min-height: 120px;
}

.headerWrapper {
  background-color: $black;
}
