// src\pages\JobBoard\Post\Post.module.scss

@import '../../../styles/mdr';

$post-width: 900px !default;

.postJob {
  max-width: $post-width;
  margin: 0 auto;

  .jobSubmitError {
    margin-top: 25px;
    margin-bottom: -40px;

    .apiError {
      color: $error;
      background-color: $error-box;
      font-size: 12px;
      padding: 11px;
      text-align: center;
    }
  }

  .postMainTitle {
    @include font-size(44px);
    @include margin-top(25px);

    font-family: $graphik;
    font-weight: bold;
    letter-spacing: -1.22px;
    color: #0f0f0f;
  }

  .postSubTitle {
    @include font-size(16px);
    @include margin(5px 0);

    font-family: $opensans;
    letter-spacing: -0.44px;
    color: #5a5a5a;
  }

  .postNavLabel {
    @include font-size(16px);
    @include margin(25px 0 35px 0);

    font-family: $graphik;
    font-weight: bold;
    letter-spacing: -0.44px;
    text-align: left;
    color: $black;
  }

  .typeSwitcher {
    @include margin(25px 0);

    .postTabs {
      border: 0;

      .postTab {
        @include media-breakpoint-down(md) {
          @include margin-bottom(50px);
        }

        position: relative;
        border: 1px solid #979797;
        border-radius: 0;
        min-height: 230px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .postTabTitle {
          @include font-size(20px);
          @include margin-bottom(10px);

          font-family: $graphik;
          font-weight: 500;
          line-height: 1.3;
          letter-spacing: -0.56px;
          text-align: center;
          color: $black;
          transition: 0.3s border-color ease-in-out;
        }

        .postTabDesc {
          @include font-size(14px);

          font-family: $opensans;
          line-height: 1.57;
          letter-spacing: -0.39px;
          text-align: center;
          color: #909090;
          max-width: 95%;
        }

        &:focus {
          outline: none;
          box-shadow: 0;
        }

        &:hover {
          border-color: #9143fe;
        }

        &::before {
          display: flex;
          justify-content: center;
          align-items: center;
          content: '✔';
          position: absolute;
          left: 0;
          top: -18.5px;
          right: 0;
          margin-left: auto;
          margin-right: auto;
          width: 37px;
          height: 37px;
          border-radius: 50%;
          background-color: #9143fe;
          color: $white;
          transform: scale(0);
          transition: 0.3s transform ease-in-out;
        }

        &[aria-selected="true"] {
          border-color: #9143fe;

          &::before {
            transform: scale(1);
          }
        }
      }
    }
  }
}
