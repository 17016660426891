@import '../../../../styles/mdr';

.dashboardSection {
  position: relative;
  min-height: 120px;
}

.sectionHeading {
  @include font-size(24px);

  line-height: 34px;
  letter-spacing: 0.67px;
  font-weight: 300;
  color: $black;

  @include margin-bottom(30px);
}

.profileInfo {
  @include font-size(16px);

  line-height: 20px;
  font-weight: 500;
  color: $medium-gray;
  padding-bottom: 6px;
  border-bottom: 1px solid $gray;

  @include margin-top(30px);
  @include margin-bottom(22px);

  span {
    display: block;
    font-weight: 300;

    @include font-size(13px);
  }
}

.authLabel {
  @include font-size(16px);

  font-weight: bold;
  letter-spacing: -0.44px;
  margin-bottom: 8px;
}

.authInput {
  @include margin-bottom(25px);

  position: relative;

  &.authInputMultiselect {
    input {
      @include margin-bottom(0);
    }
  }

  .errorInput {
    border-color: $red !important;
  }
}

.authButton {
  @include margin-top(50px);
}

.success {
  color: green;
  margin-top: 5px;
}

.error {
  position: absolute;
  color: $red;
}

.infoHover {
  display: inline-block;

  @include border-radius(50%);

  background: $gray;
  color: $white;
  height: 16px;
  width: 16px;
  line-height: 16px;
  font-weight: 500;

  @include margin-left(5px);
  @include font-size(12px);

  text-align: center;
}

.shortMess {
  display: inline-block;

  @include margin-left(5px);

  color: $branding1;

  @include font-size(14px);
}
