@import '../../../../../styles/mdr';

.sectionHeading {
    @include font-size(24px);
    line-height: 34px;
    letter-spacing: 0.67px;
    font-weight: 300;
    color: $black;
    @include margin-bottom(30px);

    .messageUser {
      font-weight: 700;
    }
}

.messageListing {
  border: 1px solid $light-gray;
  @include padding(20px 30px);

  @include media-breakpoint-down(sm) {
    @include padding(15px);
  }

  .messageDetailsTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include margin-bottom(36px);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    a {
      @include font-size(14px);
      font-weight: 500;
      color: $gray;

      @include media-breakpoint-down(sm) {
        @include margin-bottom(15px);
      }

      &:hover {
        color: $branding1;
      }

      svg {
        @include margin-right(10px);
      }
    }

    button {
      @include font-size(12px);
    }
  }
  nav {
    border: none;
    @include margin-bottom(36px);

    a {
      border: none;
      padding: 0 5px;
      font-weight: 500;
      color: $gray;
      @include font-size(16px);
      line-height: 1;
    }
  }

  .chatBox {
    .list{
      @include margin-bottom(20px);
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      &.listClient {
          justify-content: flex-start;

          .img {
            order: 1;
            text-align: left;
          }
          .messageInfo {
            order: 2;
            border: 1px solid $branding1;
          }
      }

      .img {
          width: 65px;
          order: 2;
          text-align: right;

          img {
            max-width: 50px;
            height: auto;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }
      }
      .messageInfo {
        flex: 0 0 calc(100% - 130px);
        max-width: calc(100% - 130px);
        font-family: $graphik;
        @include padding(20px);
        border: 1px solid $light-gray;

        @include media-breakpoint-down(sm) {
          @include padding(10px);
          flex: 0 0 calc(100% - 65px);
          max-width: calc(100% - 65px);
        }

        .user {
          @include font-size(12px);
          line-height: 16px;
          letter-spacing: -0.19px;
          color: $branding1;
          font-weight: 500;
        }

        .time {
          @include font-size(12px);
          line-height: 16px;
          letter-spacing: -0.19px;
          color: $gray;
          font-weight: 500;
        }

        .MessageHeading {
          @include font-size(20px);
          line-height: 26px;
          letter-spacing: -0.56px;
          color: $black;
          font-weight: 400;
          @include margin-bottom(15px);

          a {
            color: $black;
          }
        }
      }
    }
  }
}

.textAreaOuter {
  @include padding(0 65px);
  @include margin-bottom(20px);

  @include media-breakpoint-down(sm) {
    @include padding(0);
  }

  textarea {
    resize: none;
  }
}

.btnOuter {
  @include padding(0 65px);

  @include media-breakpoint-down(sm) {
    @include padding(0);
  }
}

.messageList {

  .list{
    @include margin-bottom(55px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .img {
        width: 65px;
        order: 2;
        text-align: right;

        img {
          max-width: 50px;
          height: auto;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;
        }
    }
    .messageInfo {
      order: 1;
      flex: 0 0 calc(100% - 65px);
      max-width: calc(100% - 65px);
      font-family: $graphik;

      .user {
        @include font-size(12px);
        line-height: 16px;
        letter-spacing: -0.19px;
        color: $branding1;
        font-weight: 500;
      }

      .time {
        @include font-size(12px);
        line-height: 16px;
        letter-spacing: -0.19px;
        color: $gray;
        font-weight: 500;
      }

      .MessageHeading {
        @include font-size(20px);
        line-height: 26px;
        letter-spacing: -0.56px;
        color: $black;
        font-weight: 500;
        @include margin-bottom(15px);

        a {
          color: $black;
        }
      }
    }
  }
}
