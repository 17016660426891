@import '../../styles/mdr';

.fileUpload {
  .fileSpecs {
    @include font-size(0.8rem);
    @include padding(15px 1px);

    color: rgb(180, 180, 180);
    font-size: 0.8rem;
    font-style: italic;

    li {
      list-style-type: none;
    }

    .spec {
      @include padding(2px 1px);
    }
  }

  .imageType {
    width: 200px;
    height: 200px;
    border: 1px solid rgb(180, 180, 180);
    border-radius: 5px;

    img {
      width: 200px;
      height: 200px;
      border: 1px solid rgb(180, 180, 180);
      border-radius: 5px;
    }
  }

  .fileSelectedName {
    @include padding(8px 10px);

    font-size: 0.8rem;
    font-weight: bold;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
  }

  .removeFile {
    margin-top: 5px;
    font-size: 0.7rem;
    font-weight: bold;
    float: right;
    color: $branding1;
  }

  .removeFile:hover {
    cursor: pointer;
  }
}

#imageUpload {
  display: none;
}

.preview {
  height: 200px;
  width: 200px;
}
