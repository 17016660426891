// src\styles\abstracts\overrides.scss

$branding1: #00a2e1;
$branding1-1: #e1fff1;
$branding1-light: #01335d;
$branding2: #09d00b;
$branding3: #9143fe;

$dark-gray: #1c1c1c;

$footer-color: #01335d;
$heading-color: #00375f;
$image-background-color: #ffc300;
$social-background-color: #ffc300;
$footer-logo-width: 60px;
$header-logo-width: 155px;
$nav-underline-color: #1c1c1c;