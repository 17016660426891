// src\pages\JobBoard\Page\components\Header\Header.module.scss

@import '../../../../../styles/mdr';

.resultHeader {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 175px;
  background-color: $black;
  color: $white;

  @include media-breakpoint-down(md) {
    @include padding(25px 0);

    min-height: 0;
  }

  .resultHeaderRow {
    @include padding-top(20px);
    @include padding-bottom(40px);

    @include media-breakpoint-down(md) {
      @include padding(0);

      > div {
        @include padding-left(5px);
        @include padding-right(5px);
      }
    }
  }

  .title {
    @include font-size(36px);
    @include margin-bottom(15px);

    font-family: $graphik;
    font-weight: bold;
    line-height: 1.1;
    letter-spacing: -1px;
    color: $white;
  }

  .jobData {
    @include font-size(19px);

    font-family: $graphik;
    font-weight: 500;
    letter-spacing: -0.54px;
    color: $white;

    svg {
      @include margin-right(5px);

      color: #7f7f7f;
    }

    .label {
      text-transform: capitalize;

      @include media-breakpoint-down(sm) {
        display: block;
      }

      svg {
        margin-left: 0;
      }
    }

    .location {
      text-transform: capitalize;

      @include media-breakpoint-down(sm) {
        display: block;
      }

      svg {
        @include margin-left(25px);

        @include media-breakpoint-down(sm) {
          @include margin-top(10px);
          @include margin-left(0);
        }
      }
    }
  }

  .resultHeaderButton {
    @include media-breakpoint-down(md) {
      .resultHeaderAmount {
        @include margin-bottom(10px);
      }
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;

      .resultHeaderAmount {
        @include font-size(22px);
        @include margin-bottom(5px);

        font-family: $graphik;
        align-self: center;
        justify-self: center;
        font-weight: bold;
        color: $white;
      }

      button,
      a {
        max-width: 100%;
        width: 100%;
      }
    }
  }
}

$modal-width: 690px;

.modalWrap {
  .modalContainer {
    max-width: $modal-width;
    margin: 0 auto;
  }

  .modalHeader {
    @include padding(40px 0 25px 0);

    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 100%;
    border: 0;
    width: 100%;

    button {
      color: $dark-gray;
      position: absolute;
      right: 15px;
      top: 5px;
      opacity: 1;
      margin: 0;
      padding: 0;

      span {
        @include font-size(50px);

        display: inline-flex;
        height: auto;
        font-weight: 100;
        transition: 0.3s color ease-in-out;
        opacity: 1;

        &:hover {
          color: $branding1;
        }
      }

      &:focus,
      &:active {
        outline: none;
        border: 0;
      }
    }

    .modalSubTitle {
      @include font-size(14px);
      @include padding-bottom(12px);

      font-family: $graphik;
      font-weight: 500;
      color: #434343;
    }

    .modalTitle {
      @include font-size(36px);

      font-family: $graphik;
      font-weight: bold;
      line-height: 0.72;
      letter-spacing: -1px;
      color: $black;
    }
  }

  .modalDataWrap {
    @include margin(0 15px auto auto);
    @include padding(15px 5px);

    border: 1px solid #d7d7d7;
    border-radius: 5px;
    max-width: $modal-width - 30px;
    margin: 0 auto;

    .modalAvatar {
      max-width: 80px;
      margin-right: 5px;

      img {
        height: 70px;
        width: 70px;
        border-radius: 5px;
      }
    }

    .modalDataName {
      font-family: $graphik;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.44;
      letter-spacing: -0.28px;
      color: #323232;
    }

    .modalDataInfo {
      font-family: $graphik;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.86;
      letter-spacing: -0.22px;
      color: #323232;
    }

    .modalDataPipe {
      display: inline-block;
      margin: 0 10px;
    }

    .modalDataLinks {
      svg {
        font-size: 14px;
        margin-right: 7px;

        path {
          fill: $branding1;
        }
      }

      a {
        margin-right: 15px;
        font-size: 14px;
      }
    }
  }

  .modalInputWrap {
    .modalInputLabel {
      @include font-size(16px);
      @include margin-right(15px);

      font-family: $graphik;
      font-weight: bold;
      letter-spacing: -0.44px;
      color: $black;
    }

    .modalCheckBoxes {
      @include margin(25px 0 15px);

      display: inline-flex;
      justify-content: center;
      align-items: flex-start;

      .modalCheck {
        @include margin-right(15px);

        > div {
          @include margin-right(15px);

          min-height: auto;
        }
      }
    }

    .modalInput {
      display: inline-flex;
      justify-content: center;
      align-items: flex-start;
      width: 100%;
      max-width: 100%;
    }
  }

  .myResult {
    @include padding(12px 26px);

    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid $branding1;
    border-radius: 0.25rem;
    font-family: $graphik;

    .resultIcon {
      font-size: 30px;
    }

    .resultData {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      @include padding-left(24px);
    }

    .resultName {
      @include font-size(16px);

      font-weight: 500;
      line-height: 1.41;
      color: #323232;
    }

    .resultFileName {
      @include font-size(12px);

      font-weight: 300;
    }

    .resultAddRemove {
      @include font-size(12px);

      color: $branding1;
      margin-left: auto;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .modalFooter {
    @include font-size(16px);

    max-width: $modal-width;
    margin: 0 auto;
    border: 0;
    font-family: $opensans;
    line-height: 1.38;
    letter-spacing: -0.44px;
    text-align: center;
    color: #909090;
  }
}

.fileInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: italic;
  letter-spacing: -0.33px;
  font-family: $opensans;

  @include font-size(12px);

  color: $gray;

  @include margin-top(10px);
}

.applicationSentModalWrap {
  .applicationModalBody {
    border: 3px solid #3d83fa;

    @include padding(15px 35px 65px);
  }

  .applicationReceived {
    @include padding(20px);

    text-align: center;
    color: green;
    font-weight: bold;

    .planeIconContainer {
      font-weight: bold;
      margin-bottom: 25px;

      .planeIcon {
        font-weight: bold;
      }
    }

    .smallText {
      @include font-size(12px);

      color: $black;
      font-weight: 600;
    }

    .bigText {
      @include font-size(26px);

      font-weight: 900;
      color: $black;
    }
  }
}
