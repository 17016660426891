// src\pages\JobBoard\Page\components\Sidebar\Sidebar.module.scss

@import '../../../../../styles/mdr';

.sidebarBlock {
  .sidebarImg {
    img {
      @include margin-bottom(30px);

      border-radius: 5px;
      border: 1px solid #e3e3e3;
      height: 170px;
      width: 170px;

      @include media-breakpoint-down(md) {
        @include margin-top(25px);
        @include margin-bottom(25px);
      }
    }
  }

  .skill {
    @include padding(5px 15px);
    @include margin-right(5px);
    @include margin-top(5px);
    @include font-size(14px);

    display: inline-block;
    color: #8c8c8c;
    border: 1px solid lightgrey;
    border-radius: 50px;
  }

  .sidebarLabel {
    @include font-size(12px);
    @include padding(10px 0 0 0);

    border-top: 1px solid #e3e3e3;
    font-family: $graphik;
    font-weight: 500;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #8d8d8d;

    &.noBorder {
      border-top: none;
    }
  }

  .sidebarResult {
    @include font-size(16px);
    @include padding(15px 0);

    font-family: $graphik;
    font-weight: 500;
    color: $dark-gray;
    text-transform: capitalize;

    .referenceLink {
      margin-top: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: lowercase;
    }
  }

  .infoHover {
    display: inline-block;
    text-transform: lowercase;

    @include border-radius(50%);

    background: $gray;
    color: $white;
    height: 16px;
    width: 16px;
    line-height: 16px;
    font-weight: 500;

    @include margin-left(5px);
    @include font-size(12px);

    text-align: center;
  }
}
