@import '../../../../styles/mdr';

.sectionHeading {
  @include font-size(24px);

  line-height: 34px;
  letter-spacing: 0.67px;
  font-weight: 300;
  color: $black;

  @include margin-bottom(30px);
}

.noCertificate {
  @include margin-bottom(40px);
  @include padding(20px 15px 32px);
  border: 1px solid lighten($medium-500, 55%) ;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  @include media-breakpoint-down(sm) {
    @include padding(15px);
  }

  .noCertBadge {
    @include margin-bottom(8px);
  }

  .noResumeHeading {
    @include font-size(18px);
    font-weight: 700;
  }

  .noResumeDate {
    @include font-size(14px);
    font-weight: 500;
    letter-spacing: -0.22px;
  }
}