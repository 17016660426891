// src\styles\abstracts\_variables.scss

$branding1: #008448 !default;
$branding-light: #00ba7e !default;
$branding1-1: #e1fff1 !default;
$branding2: #510084 !default;
$branding2-1: #f6f0ff !default;
$branding3: #fff7b2 !default;
$branding3-1: #fffddd !default;

$white: #fff !default;
$black: #000 !default;
$light-gray: #d7d7d7 !default;
$gray: #909090 !default;
$light-pink: #ffe6e6 !default;
$medium-gray: #323232 !default;
$medium-500: #5a5a5a !default;
$dark-gray: #1c1c1c !default;
$red: #d93025 !default;
$light-blue: #e9f5ff !default;
$blue: #097ad0 !default;
$xlight-blue: #09B1D0 !default;
$error-box: #ffeeee !default;
$error: #ff7b7b !default;
$xxlight-blue: #f8fffc !default;
$xlight-green: #c6fce4 !default;
$blue-100: #fafdff !default;
$blue-600: #89ccff !default;

$footer-color: #fff7b2 !default;
$heading-color: #1c1c1c !default;
$footer-logo-width: 63px !default;
$header-logo-width: 90px !default;


$graphik: 'Graphik',
sans-serif;
$opensans: 'Open Sans',
sans-serif;