// src\styles\mixins\_gradient.scss

// @include gradient(red, black, 'y or x');
@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;

  @if $orientation == 'y' {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  }

  @else if $orientation == 'x' {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  }

  @else {
    background: -webkit-radial-gradient(center, ellipse cover, $start-color, $end-color);
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}
